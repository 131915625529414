<template>
  <div class="data_control_half_left_holder">
    <div id="datasetsControl" class="data_control_half_left" v-on:click='routerLink("/datasets")'>
      <div class="control_icon"><i class="fas fa-database"></i></div>
      <div class="control_title">DATASETS</div>
    </div>
    <div id="aboutControl" class="data_control_half_left" v-on:click='routerLink("/about")'>
      <div class="control_icon"><i class="fas fa-info-circle"></i></div>
      <div class="control_title">ABOUT</div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'DatasetsAboutControl',
  data: () => ({
    show: false
  }),
  methods: {
    routerLink: function (path) {
      if (this.$route.path === path) {
        router.push("/")
      } else {
        router.push(path);
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>