<template>
  <div class="explore_more_tools_holder">
    <div id="aboutControl" class="data_control_half_left" v-on:click='routerLink("/more-tools")'>
      <div class="control_icon"><i class="fas fa-hand-pointer"></i></div>
      <div class="control_title">MORE TOOLS</div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'MoreToolsControl',
  data: () => ({
    show: false
  }),
  methods: {
    routerLink: function (path) {
      if (this.$route.path === path) {
        router.push("/")
      } else {
        router.push(path);
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>