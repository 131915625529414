<template>
  <div id="fullPage" v-if="show">
    <LeftPane></LeftPane>
    <div class="rightPaneHolder">
      <div class="rightPaneTop">
        <RightPaneBottom></RightPaneBottom>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPane from '@/components/LandingPage/LeftPane'
import RightPaneBottom from '@/components/LandingPage/RightPaneBottom'

export default {
  name: 'FullPageView',
  components: {
    LeftPane, RightPaneBottom
  },
  data: () => ({
    show: false
  }),
  mounted: function () {
    let self = this;
    if (this.$store.getters["globePersistedStore/isFirstLoad"]) {
      setTimeout(() => {
        self.show = true;
      }, 4000)
    } else {
      self.show = true;
    }

  }
}
</script>

<style scoped lang="less">
/* FULL PAGE */
#fullPage {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  /* Initially hide as page loader is shown */
}

#fullPage::-webkit-scrollbar{
  display: none;
}
</style>