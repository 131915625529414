

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";

// const ls = new SecureLS({isCompression: false});
Vue.use(Vuex)

import {globePersistedStore} from "./home/module";
import {unpersistedStore} from "./unpersisted/module";

const dataState = createPersistedState({
    paths: ['globePersistedStore']
})

export default new Vuex.Store({
    modules: {
        globePersistedStore,
        unpersistedStore
    },
    plugins: [dataState]
})
