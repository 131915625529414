<template>
  <div id="sourceInfoControl" class="data_control_left">
    <div id="sourceInfoControlHeader" class="control_header">
      <div class="control_icon"><i class="fab fa-sourcetree"></i></div>
      <div class="control_title">SOURCE INFO</div>
      <div class="control_checkbox">
        <label class="switch">
          <input id="sourceInfoControlCheckbox" type="checkbox" v-model="enabled">
          <span class="slider"></span>
        </label>
      </div>
    </div>
    <transition name="slide-fade">
      <div id="sourceInfoControlBody" class="control_body" v-if="showBody">
        <!-- Source Info Control Body -->
        <div id="sourceNumberSelectorContainer">
          <p>DISPLAY ALL TIME SOURCES</p>
          <p><strong>ATTACKING MORE THAN</strong></p>
          <div id="sourceNumberSelector">
            <div id="sourceMinus" v-on:click="decreaseBorder"><i class="fas fa-minus"></i></div>
            <div id="sourceNum">{{border}}</div>
            <div id="sourcePlus" v-on:click="increaseBorder"><i class="fas fa-plus"></i></div>
          </div>
          <p>HONEYPOTS</p>
        </div>
      </div>
    </transition>
    <div id="sourceInfoControlFooter" class="control_footer">
      <div v-bind:class="{'control_icon': true, 'is-opened':!showBody}" v-on:click="toggleView()"><i class="fas fa-chevron-up"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SourceInfoControl',
  data: () => ({
    showBody: true
  }),
  computed: {
    enabled: {
      get: function () {
        return this.$store.getters["globePersistedStore/isSourceInfoEnabled"];
      },
      set: function () {
        this.$store.commit("globePersistedStore/toggleSourceInfo")
      }
    },
    border: {
      get: function () {
        return this.$store.getters["globePersistedStore/getSourceInfoBorder"];
      }
    }
  },
  watch: {
    enabled: function () {
      this.showBody = this.enabled;
    }
  },
  methods: {
    toggleView: function () {
      this.showBody = !this.showBody;
    },
    increaseBorder: function () {
      this.$store.commit("globePersistedStore/increaseSourceInfoBorder")
    },
    decreaseBorder: function () {
      this.$store.commit("globePersistedStore/decreaseSourceInfoBorder")
    }
  },
  created() {
    if(!this.$store.getters["globePersistedStore/isRealtimeDataEnabled"]){
      this.showBody = false;
    }
  },
  mounted() {
    this.$root.$on("hide-source-info-pane",()=>{
      this.showBody = false;
    })
  },
  deactivated() {
    this.$root.$off("hide-realtime-pane");
  }
}
</script>

<style scoped lang="less">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.25s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to {
  margin-top: -170px;
}

/* Source Info */
#sourceNumberSelectorContainer {
  text-transform: uppercase;
  font-weight: 300;
  color: white;
  font-size: 13px;
  text-align: center;
  text-justify: distribute;
  letter-spacing: 0.095em;
}
#sourceNumberSelectorContainer p {
  margin: 5px;
}
#sourceNumberSelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}
#sourceMinus, #sourcePlus {
  border: 1px solid red;
  padding: 8px;
  width: 45px;
  color: red;
  transition: all 0.1s ease-in-out;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sourceMinus:hover, #sourcePlus:hover {
  /* border-color: white; */
  color: white;
  cursor: pointer;
}
#sourceNum {
  /* border: 1px solid red; */
  width: 50px;
  padding: 8px;
  background: red;
  color: black;
  font-weight: 600;
  font-size: 15px;
}
</style>