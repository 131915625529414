import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/dist/vuetify.min.css'
import "./assets/css/common.less"

import VTooltip from 'v-tooltip'
Vue.use(VTooltip, {
    defaultDelay: 200,
    defaultOffset: 5
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
