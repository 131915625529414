import helper from "@/store/helper";

export default {
    setHash(state, newVal) {
        state.hash = newVal;
    },
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, helper.defaultState())
    },
    setContactFormSubmitted(state, newVal) {
        state.isContactFormSubmitted = newVal;
    },
    setFirstLoad(state, newVal) {
        state.firstLoad = newVal;
        if(newVal){
            state.sourceInfo.borderMin = 1;
        }
    },
    setLeftPane(state, newVal) {
        state.showLeftPane = newVal;
    },
    toggleLeftPane(state) {
        state.showLeftPane = !state.showLeftPane;
    },
    toggleRealtimeData(state) {
        state.realtimeData.enabled = !state.realtimeData.enabled;
    },
    setBottomPaneTimeSpan(state, newVal) {
        state.bottomPane.timeSpan = newVal;
    },
    toggleSourceInfo(state) {
        state.sourceInfo.enabled = !state.sourceInfo.enabled;
    },
    increaseSourceInfoBorder(state) {
        if (state.sourceInfo.border < state.sourceInfo.borderMax) {
            if (state.sourceInfo.border < 10) {
                state.sourceInfo.border += 1;
            } else {
                state.sourceInfo.border += state.sourceInfo.borderStep;
            }
        }
    },
    decreaseSourceInfoBorder(state) {
        if (state.sourceInfo.border > state.sourceInfo.borderMin) {
            if (state.sourceInfo.border <= 10) {
                state.sourceInfo.border -= 1;
            } else {
                state.sourceInfo.border -= state.sourceInfo.borderStep;
            }
        }
    },
    toggleAttackDensity(state) {
        state.attackDensity.enabled = !state.attackDensity.enabled;
    },
    toggleDemoMode(state) {
        state.demoMode.enabled = !state.demoMode.enabled;
    },
    setDemoMode(state, newVal) {
        state.demoMode.enabled = newVal;
    },
    toggleBottomPane(state) {
        state.bottomPane.enabled = !state.bottomPane.enabled;
    },
    setBottomPane(state, newVal) {
        state.bottomPane.enabled = newVal;
    },
    setSearchInsertedInput(state, val) {
        state.search.insertedInput = val;
    },
    setSearchInputInserted(state, val) {
        state.search.isInputInserted = val;
    },
    setSearchIsFileInserted(state, val) {
        state.search.isFileInserted = val;
    },
    setSearchInsertedError(state, val) {
        state.search.isInsertedError = val;
    },
    setSearchIPInserted(state, val) {
        state.search.isIPInserted = val;
    },
    setSearchASNInserted(state, val) {
        state.search.isASNInserted = val;
    },
    resetSearchData(state) {
        state.search = {
            isInputInserted: false,
            insertedInput: "",
            isIPInserted: false,
            isASNInserted: false,
            isInsertedError: false,
            insertedError: ""
        };
    }
}