<template>
  <v-app>
    <div id="fullPage">
      <LeftPane/>
      <div class="rightPaneHolder">
        <div class="rightPaneTop">
          <CloseOverlayButton/>
          <div class="rightPaneTop">
            <div id="aboutView">
              <!-- About us description -->
              <div id="about-description">
                <div class="header">
                  <h1>About CyberLab</h1>
                </div>
                <div class="body">
                  <p>CyberLab is a large geographically distributed network of honeypots deployed on more than 50 locations in Europe and USA that continuously monitors
                    cybersecurity attacks and collects large volumes of data about who the attackers are, what they are after, who are the most attractive targets, how
                    long they stay on the attacked nodes and what tools they use, and much much more.</p>
                </div>
                <div class="logos">
                  <p>The project is built and maintained by LTFE - Laboratory for Telecommunications, Faculty of Electrical Engineering, University of Ljubljana.</p>
                  <a href="https://www.ltfe.org/" target="_blank"><img src="../assets/logo-ltfe.png" alt="LTFE logo"></a>
                </div>
              </div>
              <!-- Title -->
              <h1 id="contact-title">Contact us</h1>
              <div id="about-contact-holder">
                <ContactUs intent="about"></ContactUs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>

import LeftPane from '@/components/LandingPage/LeftPane'
import CloseOverlayButton from '@/components/Common/CloseOverlayButton'
import ContactUs from '@/components/Common/ContactUs'

export default {
  name: 'About',
  components: {
    LeftPane, CloseOverlayButton, ContactUs
  },
  mounted() {
    this.$root.$emit("hide-realtime-pane")
    this.$root.$emit("hide-source-info-pane")
  }
}
</script>

<style scoped lang="less">
/* About View */
#aboutView {
  padding: 15px 80px 200px 80px;
  pointer-events: auto;
  background: black;
  overflow-y: scroll;
  /* Disable FF scrollbars */
  scrollbar-width: none;
  /* Disable IE 10+ scrollbars */
  -ms-overflow-style: none;
  color: white;
  height: 100vh;

  .header {
    color: white;

    h1 {
      /* Make space for the close pane button */
      padding-right: 40px;
    }
  }

  .body {
    color: white;
    font-weight: 300;
    text-align: justify;
    padding-bottom: 2.5px;
  }

  .logos {
    color: white;
    font-weight: 300;
    text-align: justify;

    img {
      width: 180px;
      height: auto;
      margin-bottom: 35px;
    }
  }
}

#aboutView::-webkit-scrollbar {
  display: none;
}

#fullPage {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  /* Initially hide as page loader is shown */
}
</style>
