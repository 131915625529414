/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
import axios from "axios";
import helper from "@/store/helper";

export default {
    updateRealtimeData({_commit, state}) {
        axios
            .get(state.api.url + '/counter_data', {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                state.realtimeData.activeHackers = helper.zeroFill(response.data.active_users, 4);
                state.realtimeData.nodesAttacked = helper.zeroFill(response.data.active_nodes, 4);
                state.realtimeData.lastLocation = response.data.last_location;
            })
    },
    getIpsData({commit, state, rootState}, ip) {
        rootState.unpersistedStore.searchData.fileData = [];
        // state.search.insertedInput = "";
        commit("setSearchInputInserted", "");
        commit("unpersistedStore/requestStarted", null, { root: true });
        // state.search.isInputInserted = false;
        commit("setSearchInputInserted", false);
        rootState.unpersistedStore.searchData.ipData = [];
        rootState.unpersistedStore.searchData.asnData = [];
        axios
            .get(rootState.globePersistedStore.api.search + '/ips/' + ip, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data.status === "OK") {
                    if (response.data.asn) {
                        commit("unpersistedStore/requestStarted", null, { root: true });
                        axios
                            .get(rootState.globePersistedStore.api.search + '/asn/' + response.data.asn, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(resp => {
                                commit("unpersistedStore/requestFinished", null, { root: true });
                                if (response.data.status === "OK") {
                                    commit("setSearchInsertedInput", ip);
                                    commit("setSearchInputInserted", true);
                                    commit("setSearchIPInserted", true);
                                    commit("setSearchIPInserted", true);
                                    commit("setSearchASNInserted", false);
                                    commit("setSearchInsertedError", false);
                                    commit("setSearchIsFileInserted", false);
                                    rootState.unpersistedStore.searchData.ipData = response.data;
                                    rootState.unpersistedStore.searchData.asnData = resp.data;
                                } else {
                                    commit("setSearchInsertedInput", ip);
                                    commit("setSearchInputInserted", false);
                                    commit("setSearchIPInserted", true);
                                    commit("setSearchInsertedError", true);
                                    commit("setSearchIsFileInserted", false);
                                }
                            });
                        rootState.unpersistedStore.searchData.asnAbuseData = {};
                        commit("unpersistedStore/requestStarted", null, { root: true });
                        axios
                            .get(rootState.globePersistedStore.api.searchPro + '/abuse/summary/asn/' + response.data.asn, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(response => {
                                commit("unpersistedStore/requestFinished", null, { root: true });
                                if (response.data) {
                                    rootState.unpersistedStore.searchData.asnAbuseData = response.data;
                                }
                            });
                    } else {
                        commit("setSearchInsertedInput", ip);
                        commit("setSearchInputInserted", true);
                        commit("setSearchIPInserted", true);
                        commit("setSearchASNInserted", false);
                        commit("setSearchInsertedError", false);
                        commit("setSearchIsFileInserted", false);
                        rootState.unpersistedStore.searchData.ipData = response.data;
                    }
                } else {
                    commit("setSearchInsertedInput", ip);
                    commit("setSearchInputInserted", false);
                    commit("setSearchIPInserted", true);
                    commit("setSearchInsertedError", true);
                    commit("setSearchIsFileInserted", false);
                }
            })
            .catch(function (error) { //TODO tukaj lahko daš malformed IP
                commit("unpersistedStore/requestFinished", null, { root: true });
                console.log(error);
                // IP not found in the database
                state.search.insertedInput = ip;
                state.search.isInputInserted = false;
                state.search.isInsertedError = true;
                state.search.isFileInserted = false;
                commit("setSearchIsFileInserted", false);
            });
        rootState.unpersistedStore.searchData.ipAbuseData = {};
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/abuse/summary/ip/' + ip, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data) {
                    rootState.unpersistedStore.searchData.ipAbuseData = response.data;
                }
            });
        rootState.unpersistedStore.searchData.ipEventsData = {};
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/abuse/events/ip/' + ip, {
                params: {limit: 7},
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data) {
                    rootState.unpersistedStore.searchData.ipEventsData = response.data;
                }
            });
        rootState.unpersistedStore.searchData.ipFilesData = [];
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/files/summary/ip/' + ip, {
                params: {},
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data) {
                    rootState.unpersistedStore.searchData.ipFilesData = response.data.seen_files;
                }
            });
    },
    getAsnData({commit, state, rootState}, asn) {
        rootState.unpersistedStore.searchData.fileData = [];
        rootState.unpersistedStore.searchData.ipData = [];
        rootState.unpersistedStore.searchData.asnData = [];
        state.search.insertedInput = "";
        state.search.isInputInserted = false;
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.search + '/asn/' + asn, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data.status === "OK") {
                    commit("setSearchInsertedInput", asn);
                    commit("setSearchInputInserted", true);
                    commit("setSearchIPInserted", false);
                    commit("setSearchASNInserted", true);
                    commit("setSearchInsertedError", false);
                    commit("setSearchIsFileInserted", false);
                    rootState.unpersistedStore.searchData.asnData = response.data;
                } else {
                    commit("setSearchInsertedInput", asn);
                    commit("setSearchInputInserted", false);
                    commit("setSearchIPInserted", false);
                    commit("setSearchASNInserted", true);
                    commit("setSearchInsertedError", true);
                    commit("setSearchIsFileInserted", false);
                }

            })
            .catch(function (error) { //TODO tukaj lahko daš malformed IP
                commit("unpersistedStore/requestFinished", null, { root: true });
                console.log(error);
                // ASN not found in the database
                commit("setSearchInsertedInput", asn);
                commit("setSearchInputInserted", false);
                commit("setSearchIPInserted", false);
                commit("setSearchASNInserted", true);
                commit("setSearchInsertedError", true);
                commit("setSearchIsFileInserted", false);
            });
        rootState.unpersistedStore.searchData.asnAbuseData = {};
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/abuse/summary/asn/' + asn, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data) {
                    rootState.unpersistedStore.searchData.asnAbuseData = response.data;
                }
            });
        rootState.unpersistedStore.searchData.asnEventsData = {};
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/abuse/events/asn/' + asn, {
                params: {limit: 7},
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data) {
                    rootState.unpersistedStore.searchData.asnEventsData = response.data;
                }
            });
    },
    getFileData({commit, state, rootState}, fileHash) {
        rootState.unpersistedStore.searchData.fileData = [];
        state.search.insertedInput = "";
        state.search.isInputInserted = false;
        let hashType;
        if (fileHash.length === 32) {
            hashType = "md5";
        }
        if (fileHash.length === 40) {
            hashType = "sha1";
        }
        if (fileHash.length === 64) {
            hashType = "sha256";
        }
        if (!fileHash) { //malformed hash
            state.search.insertedInput = fileHash;
            state.search.isInputInserted = false;
            state.search.isInsertedError = true;
            state.search.isASNInserted = false;
            state.search.isFileInserted = true;
            return
        }
        commit("unpersistedStore/requestStarted", null, { root: true });
        axios
            .get(rootState.globePersistedStore.api.searchPro + '/files/summary/' + hashType + '/' + fileHash, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                commit("unpersistedStore/requestFinished", null, { root: true });
                if (response.data.status === "OK") {
                    commit("setSearchInsertedInput", fileHash);
                    commit("setSearchInputInserted", true);
                    commit("setSearchIPInserted", false);
                    commit("setSearchASNInserted", false);
                    commit("setSearchInsertedError", false);
                    commit("setSearchIsFileInserted", true);
                    state.search.isFileInserted = true;
                    rootState.unpersistedStore.searchData.fileData = response.data;
                } else {
                    commit("setSearchInsertedInput", fileHash);
                    commit("setSearchInputInserted", false);
                    commit("setSearchIPInserted", false);
                    commit("setSearchASNInserted", false);
                    commit("setSearchInsertedError", true);
                    commit("setSearchIsFileInserted", true);
                }
            })
            .catch(function (error) {
                commit("unpersistedStore/requestFinished", null, { root: true });
                console.log(error);
                // FILE HASH not found in the database
                commit("setSearchInputInserted", fileHash);
                commit("setSearchInputInserted", false);
                commit("setSearchIPInserted", false);
                commit("setSearchASNInserted", false);
                commit("setSearchInsertedError", true);
                commit("setSearchIsFileInserted", true);
            });
    }
}