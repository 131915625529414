
export default {
    getHash(state) {
        return state.hash;
    },
    getAPIUrl(state) {
        return state.api.url;
    },
    isContactFormSubmitted(state) {
        return state.isContactFormSubmitted;
    },
    isFirstLoad(state) {
        return state.firstLoad;
    },
    showLeftPane(state) {
        return state.showLeftPane;
    },
    getActiveHackers(state) {
        return state.realtimeData.activeHackers;
    },
    getNodesAttacked(state) {
        return state.realtimeData.nodesAttacked;
    },
    isRealtimeDataEnabled(state) {
        return state.realtimeData.enabled;
    },
    getBottomPaneTimeSpan(state) {
        return state.bottomPane.timeSpan;
    },
    isSourceInfoEnabled(state) {
        return state.sourceInfo.enabled;
    },
    getSourceInfoBorder(state) {
        return state.sourceInfo.border;
    },
    isAttackDensityEnabled(state) {
        return state.attackDensity.enabled;
    },
    isDemoModeEnabled(state) {
        return state.demoMode.enabled;
    },
    isBottomPaneEnabled(state) {
        return state.bottomPane.enabled;
    },
    searchGetInsertedInput(state) {
        return state.search.insertedInput;
    },
    searchIsInputInserted(state) {
        return state.search.isInputInserted;
    },
    searchIsInsertedError(state) {
        return state.search.isInsertedError;
    },
    searchIsIPInserted(state) {
        return state.search.isIPInserted;
    },
    searchIsASNInserted(state) {
        return state.search.isASNInserted;
    },
    searchIsFileInserted(state) {
        return state.search.isFileInserted;
    }
}