<template>
  <div id="contact">
    <div id="contact-container">
      <form id="contact-form" class="intent-<?php echo($contactIntent); ?>">
        <div class="top">
          <input v-model="inputName" type="text" placeholder="Your name / organization">
          <input v-model="inputEmail" type="email" placeholder="Your e-mail address">
          <input v-if="intent === 'about'" v-model="inputSubject" type="text" v-bind:placeholder="subjectText">
        </div>
        <div class="middle">
          <textarea v-model="inputMessage" v-bind:placeholder="messageText" cols="30" rows="10"></textarea>
        </div>
        <div class="bottom">
          <vue-recaptcha sitekey="6LcUdfcUAAAAAMm66hEd2mUF_xIZe2UyeAALefSe" size="invisible" :loadRecaptchaScript="true" @verify="onVerify" @error="onError">
            <input id="input-send" type="button" v-bind:value="sendText"/>
          </vue-recaptcha>
          <div id="input-status" v-if="isError" v-bind:class="{'is-error': redBorder}">{{ errorText }}</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from "axios";

export default {
  name: 'ContactUs',
  props: ['intent'],
  components: {
    VueRecaptcha
  },
  data: () => ({
    isError: false,
    redBorder: false,
    inputName: "",
    inputEmail: "",
    inputSubject: "",
    subjectText: "Subject",
    inputMessage: "",
    messageText: "Message",
    sendText: "Send",
    errorText: "",
  }),
  computed: {},
  methods: {
    onVerify: function () {
      if (this.intent === "datasets") {
        this.sendDatasetsContact();
      } else {
        this.sendContact();
      }
    },
    onError: function () {
      // this.errorText = "Captcha error!";
      // this.isError = true;
      // this.redBorder = true;
      this.sendDatasetsContact();
    },
    validateEmail: function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.inputEmail).toLowerCase());
    },
    checkInputs: function () {
      this.isError = false;
      this.redBorder = false;
      if (this.inputName === "") {
        this.errorText = "Enter your name!";
        this.isError = true;
        this.redBorder = true;
        return false;
      }
      if (this.inputEmail === "" || !this.validateEmail()) {
        this.errorText = "Enter your e-mail!";
        this.isError = true;
        this.redBorder = true;
        return false;
      }
      if (this.intent === "about" && this.inputSubject === "") {
        this.errorText = "Enter message subject!";
        this.isError = true;
        this.redBorder = true;
        return false;
      }
      if (this.inputMessage === "") {
        this.errorText = "Enter message body!";
        this.isError = true;
        this.redBorder = true;
        return false;
      }
      return true;
    },
    sendDatasetsContact: function () {
      if (this.checkInputs()) {
        axios
            .post(this.$store.getters["globePersistedStore/getAPIUrl"] + '/contact',{
              "name_organization": this.inputName,
              "email": this.inputEmail,
              "subject": "Datasets request",
              "message": this.inputMessage,
            },{
              headers: {"Content-Type": "application/json"}
            })
            .then(() => {
              this.isError = false;
              this.clearForm();
              this.$store.commit("globePersistedStore/setContactFormSubmitted",true);
            });
      }
    },
    sendContact: function () {
      if (this.checkInputs()) {
        axios
            .post(this.$store.getters["globePersistedStore/getAPIUrl"] + '/contact',{
              "name_organization": this.inputName,
              "email": this.inputEmail,
              "subject": this.inputSubject,
              "message": this.inputMessage,
            },{
              headers: {"Content-Type": "application/json"}
            })
            .then(() => {
              this.errorText = "Thank you for your message!";
              this.isError = true;
              this.redBorder = false;
              this.clearForm();
            });
      }
    },
    clearForm: function () {
      this.inputMessage = "";
      this.inputName = "";
      this.inputSubject = "";
      this.inputEmail = "";
    }
  },
  created() {
    if (this.intent === "datasets") {
      this.subjectText = "Datasets request";
      this.messageText = "Please let us know how you intend to use the data. Access to the downloads section will be granted immediately upon your form submission.";
      this.sendText = "Submit & Show Downloads"
    }
  }
}
</script>

<style scoped lang="less">
/* About Contact Form */
#contact-form input, #contact-form textarea {
  border-radius: 0;
  background: transparent;
  color: white;
  border: 1px solid red;
  padding: 10px;
  font-family: 'Lato', sans-serif;
}

#contact-form textarea, select, input, button {
  outline: none !important;
  font-family: 'Lato', sans-serif;
}

#contact-form input:focus {
  outline-color: transparent !important;
  outline-style: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

#contact-form .top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#contact-form .top input {
  width: 100%;
  margin-right: 15px;
  font-size: 12px;
}

#contact-form .top input:last-child {
  margin-right: 0;
}

#contact-form .middle {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 12.5px;
}

#contact-form .middle textarea {
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
}

#contact-form .bottom {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 12.5px;
  margin-left: 0;
}

#input-send {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  padding: 7px 10px;
}

#input-send:hover {
  border: 1px solid white;
  cursor: pointer;
}

#input-send:active {
  border: 1px solid gray;
  cursor: pointer;
}

#input-status {
  display: flex;
  margin-left: auto;
  border: 1px solid white;
  padding: 7px 15px 7px 15px;
  font-size: 12px;
  line-height: 12px;
  color: white;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
}

#input-status.is-error {
  border-color: red;
}

#contact-form {
  padding-bottom: 50px;
}
</style>