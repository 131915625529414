import actions from "@/store/home/actions";
import getters from "@/store/home/getters";
import mutations from "@/store/home/mutations";
import createPersistedState from "vuex-persistedstate";
import helper from "@/store/helper";

export const globePersistedStore = {
    namespaced: true,
    plugins: [createPersistedState()],
    state: helper.defaultState(),
    getters,
    mutations,
    actions
}