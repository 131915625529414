import { render, staticRenderFns } from "./ImportantAsns.vue?vue&type=template&id=06514285&scoped=true&"
import script from "./ImportantAsns.vue?vue&type=script&lang=js&"
export * from "./ImportantAsns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06514285",
  null
  
)

export default component.exports