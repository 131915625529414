<template>
  <div class="fullsize-chart-holder">
    <div class="title"><i class="fab fa-sourcetree"></i>ASNs WITH MOST ATTACK SOURCES</div>
    <div class="fullsize-chart">
      <canvas id="top_asns"></canvas>
    </div>
  </div>
</template>

<script>
const Chart = require('chart.js');

export default {
  name: 'ImportantAsns',
  data: () => ({
  }),
  methods: {
    initGraph: function () {
      const ctx_asns = document.getElementById('top_asns');
      // const self = this;
      this.graph = new Chart(ctx_asns, {
        type: 'horizontalBar',
        data: {
          labels: [],
          backgroundColor: 'transparent',
          datasets: [{
            label: ' # of sessions',
            data: [],
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            borderColor: 'red',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{
              ticks: {
                fontColor: 'white',
              }
            }]
          },
          plugins: {
            datalabels: {
              color: 'red',
              anchor: 'end',
              align: 'end',
              offset: 5,
            }
          },
          // onClick: function(c,i) {
          //   let data = self.$store.getters["unpersistedStore/getRealTimeTimespanData"];
          //   let e = i[0];
          //   if(e){
          //     const x_value = this.data.labels[e._index];
          //     const ind = data.top_asns.descriptions.indexOf(x_value);
          //     if(ind !== -1){
          //       self.$router.push({name: 'SearchText', params: {"searchText": data.top_asns.asns[ind]}})
          //     }
          //   }else{
          //     let mousePoint = Chart.helpers.getRelativePosition(c, this.chart.chart);
          //     let clickY = this.chart.scales['y-axis-0'].getValueForPixel(mousePoint.y);
          //     if(clickY !== -1){
          //       self.$router.push({name: 'SearchText', params: {"searchText": data.top_asns.asns[clickY]}})
          //     }
          //   }
          // }
        }
      });
    },
    drawGraph: function () {
      let data = this.$store.getters["unpersistedStore/getRealTimeTimespanData"];
      if (data !== null) {
        this.graph.data.labels = data.top_asns.descriptions;
        this.graph.data.datasets[0].data = data.top_asns.counts;
        this.graph.update();
      }
    }
  },
  mounted() {
    this.initGraph();
    this.drawGraph();
  },
  created() {
  },
  deactivated() {
    this.graph.destroy();
  }
}
</script>

<style scoped lang="less">

</style>