export default {
    defaultState: () => {
        return {
            hash: "",
            isContactFormSubmitted: false,
            api: {
                url: location.protocol + "//cyber.ltfe.org/api-web/v1",
                search: location.protocol + "//cyber.ltfe.org/api/v1",
                searchPro: location.protocol + "//cyber.ltfe.org/api-pro/v1"
            },
            firstLoad: true,
            showLeftPane: true,
            realtimeData: {
                enabled: true,
                activeHackers: "--",
                nodesAttacked: "--",
                lastLocation: {
                    lat: 0,
                    lng: 0
                }
            },
            sourceInfo: {
                enabled: false,
                border: 10,
                borderStep: 5,
                borderMin: 1,
                borderMax: 100
            },
            attackDensity: {
                enabled: false
            },
            demoMode: {
                enabled: true
            },
            bottomPane: {
                enabled: true,
                timeSpan: 60
            },
            search: {
                isInputInserted: false,
                insertedInput: "",
                isIPInserted: false,
                isASNInserted: false,
                isFileInserted: false,
                isInsertedError: false,
                insertedError: ""
            }
        }
    },
    zeroFill: function (inputNumber, nDigits) {
        let zeroString = ""
        while (inputNumber.length < nDigits) {
            inputNumber = "0" + inputNumber;
        }
        return zeroString + inputNumber
    },
    isInputASN(input) {
        if(this.isInputIP(input)){
            return false;
        }
        let isNan = isNaN(input)
        return !isNan;
    },
    isInputIP(input) {
        return input.indexOf(".") !== -1;
    },
    translateAbuseType(type) {
        let abuseTranslate = {
            "ssh_login": "SSH login",
            "file_download": "File download",
            "hacking": "Hacking",
            "info_gather": "Info gathering",
            "telnet_login": "Telnet login"
        }
        const translate = abuseTranslate[type];
        if (translate) {
            return translate;
        } else {
            return type;
        }
    },
    lineGraphSettings: {
        line: {
            borderColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 1,
            tension: 0.5
        },
        point: {
            radius: 4,
            borderWidth: 0,
            hoverRadius: 6,
            hoverBorderWidth: 1
        }
    }
};