<template>
  <div class="column-content">
    <div class="title"><i class="fas fa-project-diagram"></i>NUMBER OF ATTACK SOURCES PER COUNTRY</div>
    <div class="holder">
      <canvas id="realtimeCountrySources"></canvas>
    </div>
  </div>
</template>

<script>
const Chart = require('chart.js');

export default {
  name: 'AttackPerCountry',
  data: () => ({
    graph: null
  }),
  methods: {
    initGraph: function () {
      const ctx_countries = document.getElementById("realtimeCountrySources").getContext("2d");
      this.graph = new Chart(ctx_countries, {
        type: 'horizontalBar',
        data: {
          labels: [],
          backgroundColor: 'transparent',
          datasets: [{
            label: ' # of sessions',
            data: [],
            backgroundColor: 'red'

          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              color: 'white',
              ticks: {
                beginAtZero: true
              }
            }],
            yAxes: [{
              ticks: {
                fontColor: 'gray'
              }
            }]
          },
          plugins: {
            datalabels: {
              color: 'white',
              anchor: 'end',
              align: 'start',
              offset: 5
            }
          }
        }
      });
    },
    drawGraph: function () {
      let data = this.$store.getters["unpersistedStore/getRealTimeTimespanData"];
      if (data !== null) {
        this.graph.data.labels = data.top_countries.countries;
        this.graph.data.datasets[0].data = data.top_countries.sessions;
        this.graph.update();
      }
    }
  },
  mounted() {
    this.initGraph();
    this.drawGraph();
  },
  created() {
  },
  deactivated() {
    this.graph.destroy();
  }
}
</script>

<style scoped lang="less">

</style>