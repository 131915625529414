export default {
    translates: {
        "ABUSE_EVENTS": "Currently we support the following abuse events:</br>" +
            "</br>" +
            "ssh_login: A detected SSH login with password and username.</br>" +
            "</br>" +
            "file_download: Detection of malicious file download in the attacked server.</br>" +
            "</br>" +
            "hacking: Detected shell command in the attacked server.</br>" +
            "</br>" +
            "info_gather: Detection of system information gathetring event.",
        "IP_ACTIVITY": "The graph displays number of detected attacks per day of a IP.",
        "ASN_ACTIVITY": "The graph displays cumulative number of detected attacks per day of a ASN.",
        "IP_ABUSE_TYPES": "The graph displays pie chart of abuse types of a IP.",
        "ASN_ABUSE_TYPES": "The graph displays pie chart of abuse types of a ASN.",
        "IP_PORTS": "The graph displays pie chart of ports used of a IP.",
        "ASN_PORTS": "The graph displays pie chart of ports used of a ASN.",
        "IP_CURRENT_SCORE": "The current IP malicous score. </br> " +
            "Malicious score ranges from 0 (less malicious) and 100 (very malicious). It is calculated using our formula, " +
            "which considers the current attacker's activity and type of the activity. The past activity is reduced depending " +
            "on the amount of time passed from the attack.",
        "IP_SCORE": "The graph displays IP malicious score through time. </br> " +
            "Malicious score ranges from 0 (less malicious) and 100 (very malicious). It is calculated using our formula, " +
            "which considers the current attacker's activity and type of the activity. The past activity is reduced depending " +
            "on the amount of time passed from the attack.",
        "ASN_SCORE": "The graph displays ASN malicious score through time. </br> " +
            "Malicious score ranges from 0 (less malicious) and 100 (very malicious). It is calculated using our formula, " +
            "which considers the current attacker's activity and type of the activity. The past activity is reduced depending " +
            "on the amount of time passed from the attack.",
        "ASN_CURRENT_SCORE": "The current ASN malicous score. </br> " +
            "Malicious score ranges from 0 (less malicious) and 100 (very malicious). It is calculated using our formula, " +
            "which considers the current attacker's activity and type of the activity. The past activity is reduced depending " +
            "on the amount of time passed from the attack.",
        "FILES_DOWNLOADED": "The list displays MD5 hashes of all files used by some IP. To display file properties click on the MD5 hash.",
        "FILES_INFO": "This section displays detailed information: <br/>file type description, <br/>malware description (if exists) and <br/>additional information " +
            "about the malware (if exists).",
        "FILES_IP_LIST": "This section displays a list of IPs that used this file.",
        "FILES_STRUCTURE": "This section displays file structure in case of a compressed files.",
        "SUB_FILES_INFO": "This section displays the list of MD5 hashes of all files that are downloaded during use of the file or are compressed in the file.",
        "USAGE_EXAMPLE": "This section displays commands of the file execution.",
        "TOTAL_EVENTS": "Total number of attacks in last 30 days.",
        "MAX_EVENTS": "Max events is a single day in last 30 days.",
        "EXECUTED_VS_UNIQUE_COMMANDS": "Count of all executed commands vs. executed commands that were new or first seen.",
        "DETECTED_IPS_OF_ASN": "Number of detected IPs of the ASN.",
        "PERCENTAGE_IPS_OF_ASN": "Percentage of detected IPs of the ASN.",
        "UNIQUENESS_OF_ASN": "Uniqueness of the IP is calculated using our formula, which considers the number of unique executed commands and files.",
        "FILE_SIZE": "Actual file size in Bytes.",
        "HONEYPOT_DETECTED": "Number of honeypots that detected the file.",
        "IP_DETECTED": "Number of IPs that used the file.",
        "CHILD_FILES": "Number of child files."
    }
};