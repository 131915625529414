<template>
  <div id="fullPageLoader" v-if="show">
    <transition name="bounce">
      <div v-bind:class="{ 'absoluteCenter': true, 'bounce-enter-active': animate }">
        <img id="loader-img-1" :src="shield" alt="">
        <img id="loader-img-2" :src="upperArrow" alt="">
        <img id="loader-img-3" :src="lowerArrow" alt="">
      </div>
    </transition>
  </div>
</template>

<script>


export default {
  name: 'FullPageLoader',
  data: () => ({
    show: true,
    animate: false,
    shield: require('../../assets/shield-full.png'),
    upperArrow: require('../../assets/upper-arrow.png'),
    lowerArrow: require('../../assets/lower-arrow.png')
  }),
  mounted: function () {
    let self = this;
    if (this.$store.getters["globePersistedStore/isFirstLoad"]) {
      setTimeout(() => this.animate = true, 1000);
      setTimeout(() => {
        self.show = false;
        self.$store.commit("globePersistedStore/setFirstLoad", false);
      }, 4000)
    } else {
      self.show = false;
    }

  }
}
</script>

<style scoped lang="less">
/* Full page loader animation */
#fullPageLoader {
  width: 100vw;
  height: 100vh;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    margin: 0;
    padding: 0;
  }

  .absoluteCenter {
    background: black;
    width: 100px;
    height: 50px;
    transform: translateY(-50%);
  }

  #loader-img-1 {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
  }

  #loader-img-2 {
    width: 100%;
    height: auto;
    position: relative;
    animation: upperArrowAnimation 1s ease-in-out forwards;
    z-index: 11;
  }

  #loader-img-3 {
    width: 100%;
    height: auto;
    position: relative;
    animation: lowerArrowAnimation 1s ease-in-out forwards;
    z-index: 12;
  }

  @keyframes upperArrowAnimation {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  @keyframes lowerArrowAnimation {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-200%);
    }
  }

  .bounce-enter-active {
    animation: pulseShield 2s infinite ease-in-out;
  }

  @keyframes pulseShield {
    0% {
      transform: scale(1) translateY(-50%);
    }
    10% {
      transform: scale(1) translateY(-50%);
    }
    20% {
      transform: scale(1.1) translateY(-50%);
    }
    50% {
      transform: scale(1) translateY(-50%);
    }
    100% {
      transform: scale(1) translateY(-50%);
    }
  }
}
</style>