
export default {
    isLoading(state) {
        return state.loader > 0;
    },
    getArcData(state) {
        return state.realtimeData.arcData;
    },
    getSourceInfoData(state) {
        return state.sourceInfo.data;
    },
    getAttackDensityData(state) {
        return state.attackDensity.data;
    },
    getRealTimeTimespanData(state) {
        return state.realtimeData.timeSpanData;
    },
    getSearchIpData(state) {
        return state.searchData.ipData;
    },
    getSearchIpAbuseData(state) {
        return state.searchData.ipAbuseData;
    },
    getSearchIpFileData(state) {
        return state.searchData.ipFilesData;
    },
    getSearchFileData(state) {
        return state.searchData.fileData;
    },
    getSearchASNData(state) {
        return state.searchData.asnData;
    },
    getSearchASNAbuseData(state) {
        return state.searchData.asnAbuseData;
    },
    getSearchIpEventsData(state) {
        return state.searchData.ipEventsData;
    },
    getSearchASNEventsData(state) {
        return state.searchData.asnEventsData;
    }
}