<template>
  <span>
    <FullPageLoader/>
    <Globe/>
    <FullPageView/>
  </span>
</template>

<script>
// @ is an alias to /src
import FullPageLoader from '@/components/LandingPage/FullPageLoader';
import FullPageView from '@/components/LandingPage/FullPageView';
import Globe from '@/components/LandingPage/Globe';

export default {
  name: 'Home',
  components: {
    FullPageLoader,
    Globe,
    FullPageView
  },
  created() {
    this.$store.commit("globePersistedStore/resetSearchData");
  }
}
</script>
