export default {
    requestStarted(state) {
        state.loader++;
    },
    requestFinished(state) {
        state.loader--;
        if (state.loader < 0) {
            state.loader = 0;
        }
    },
    setArcData(state, newVal) {
        state.realtimeData.arcData = newVal;
    },
    setSourceInfoData(state, newVal) {
        state.sourceInfo.data = newVal;
    },
    setAttackDensityData(state, newVal) {
        state.attackDensity.data = newVal;
    },
    setRealTimeTimespanData(state, newVal) {
        state.realtimeData.timeSpanData = newVal;
    }
}