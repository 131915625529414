<template>
  <div id="leftPaneHolder" v-bind:class="{'minimized': isMinimized, 'maximized': isMaximized}">

    <div id="leftPanelPullTab" v-on:click="toggleLeftPane()">
      <div class="control_icon">
        <i class="fas fa-chevron-left"></i>
      </div>
    </div>

    <div id="leftPane">
      <!-- Logo -->
      <div id="logoHolder">
        <img id="logoImage" src="../../assets/cyber-logo.svg" alt="CyberLab">
      </div>
      <!-- Search Bar -->
      <!-- <SearchBar></SearchBar> -->
      <!-- Realtime Data Control -->
      <RealtimeDataControl></RealtimeDataControl>

      <!-- Source Info Control -->
      <SourceInfoControl></SourceInfoControl>

      <!-- Attack Density Control -->
      <AttackDensityControl></AttackDensityControl>

      <!-- Demo Mode Control -->
      <DemoModeControl></DemoModeControl>

      <!-- Explore More Tools -->
      <MoreToolsControl></MoreToolsControl>

      <!-- Datasets, About -->
      <DatasetsAboutControl></DatasetsAboutControl>

      <!-- Copyright by LTFE -->
      <CopyrightByLTFE></CopyrightByLTFE>
    </div>
  </div>
</template>


<script>
import AttackDensityControl from '@/components/LandingPage/LeftPane/AttackDensityControl'
import DatasetsAboutControl from '@/components/LandingPage/LeftPane/DatasetsAboutControl'
import DemoModeControl from '@/components/LandingPage/LeftPane/DemoModeControl'
import RealtimeDataControl from '@/components/LandingPage/LeftPane/RealtimeDataControl'
import SourceInfoControl from '@/components/LandingPage/LeftPane/SourceInfoControl'
import CopyrightByLTFE from './LeftPane/CopyrightBottom'
import MoreToolsControl from './LeftPane/MoreToolsControl.vue'
// import SearchBar from "@/components/Search/SearchBar";

export default {
  name: 'LeftPane',
  components:{
    RealtimeDataControl, SourceInfoControl, AttackDensityControl, DatasetsAboutControl, DemoModeControl,
    MoreToolsControl, CopyrightByLTFE
},
  computed: {
    show: {
      get: function () {
        return this.$store.getters["globePersistedStore/showLeftPane"];
      }
    },
    isMinimized: {
      get: function () {
        return !this.show;
      }
    },
    isMaximized: {
      get: function () {
        return this.show;
      }
    }
  },
  methods: {
    toggleLeftPane: function () {
      this.$store.commit('globePersistedStore/toggleLeftPane');
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="less">
/* LEFT PANE */
#leftPaneHolder {
  background: black;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  /* #leftPane width + its padding */
  width: 350px;
  min-width: 350px;
  overflow-x: visible;
  pointer-events: auto;
  /* Disable FF scrollbars */
  scrollbar-width: none;
  /* Disable IE 10+ scrollbars */
  -ms-overflow-style: none;
  transition: all 1s ease-in-out;
  line-height:unset;
}

/* Disable WebKit scrollbars */
#leftPaneHolder::-webkit-scrollbar {
  display: none;
}

#leftPaneHolder.maximized {
  margin-left: 0;

  #leftPanelPullTab {
    left: 350px;

    .control_icon {
      transform: rotate(0deg);
      padding-right: 2.5px;
      color: gray;
    }
  }
}

#leftPaneHolder.minimized {
  margin-left: -350px;

  #leftPanelPullTab {
    left: 0;

    .control_icon {
      transform: rotate(+180deg);
      padding-right: 5px;
      color: gray;
    }
  }
}

#leftPane {
  background: black;
  padding: 25px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#leftPanelPullTab {
  position: absolute;
  color: gray;
  background: black;
  width: 25px;
  height: 50px;
  left: 350px;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;

  .control_icon {
    padding: 5px 2.5px 2.5px 2.5px;
    margin-left: -4px;
    cursor: pointer;
  }

  .control_icon:hover {
    cursor: pointer;
    color: white !important;
    transition: all 0.1s ease-out;
  }
}

#logoHolder {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
}

#logoImage {
  width: 100%;
  height: auto;
}


</style>