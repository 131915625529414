import { render, staticRenderFns } from "./DemoModeControl.vue?vue&type=template&id=08c0ce14&scoped=true&"
import script from "./DemoModeControl.vue?vue&type=script&lang=js&"
export * from "./DemoModeControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c0ce14",
  null
  
)

export default component.exports