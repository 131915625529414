<template>
  <v-app>
    <div id="fullPage">
      <LeftPane/>
      <div class="rightPaneHolder">
        <div class="rightPaneTop">
          <CloseOverlayButton/>
          <div class="rightPaneTop">
            <div id="moreToolsView">

              <!-- Tools description -->
              <br>

              <div id="about-description">
                <div class="header">
                  <h1>About this site</h1>
                </div>
                <div class="body">
                  <p>Laboratory for Telecommunications at the Faculty of Electrical Engineering, University of Ljubljana develops cybersecurity monitoring, analysis and visualization tools. This website offers a limited preview of functionalities offered by the CyberLab platform. Publicly displayed data on this site is limited to a small subset of cyberattacks captured by our distributed honeypot network. Additional data and proprietary cyberattack analysis tools are not publicly available on the website. All data is anonymized and stripped of potential sensitive information.</p>
                  <p>Please see the section below to learn more about our related projects, developed tools and private APIs.</p>
                </div>

                <br>

                <div class="header">
                  <h1>Related tools</h1>
                </div>
                <div class="more_tools_tool cyberviz">
                  <h2><i class="fas fa-project-diagram"></i>CyberViz</h2>
                  <p>The CyberViz solution is an interactive attack visualization platform that allows for the exploration, analysis, and topological classification of individual cyberattacks. It provides a graph-based approach to threat hunting and attack analysis, and offers attack visualizations resembling the Cyber Kill Chain model. The platform is designed with end-users in mind, and enables the detection, analysis, and mitigation of potential cyberthreats. The system collects, stores, transforms, and visualizes cyberattacks, and can import and export enriched data in multiple formats.</p>
                  <div class="imgs">
                    <img src="../assets/cyberviz/cyberviz-1.png" alt="">
                    <img src="../assets/cyberviz/cyberviz-2.png" alt="">
                    <img src="../assets/cyberviz/cyberviz-3.png" alt="">
                    <img src="../assets/cyberviz/cyberviz-6.png" alt="">
                    <img src="../assets/cyberviz/cyberviz-5.png" alt="">
                  </div>
                </div>

                <!-- CyberStat -->

                <div class="more_tools_tool cyberstat">
                  <h2><i class="fas fa-chart-pie"></i>CyberStat</h2>
                  <p>CyberStat offers insight into historical data and (attack) activity for AS numbers and IP addresses. Captured data includes more than two years of SSH and Telnet honeypot activity. The data is used for estimation of IP and AS malicious score based on abuse event types.</p>
                  <div class="imgs">
                    <img src="../assets/cyberstat/cyberstat.png" alt="">
                  </div>
                </div>

                <!-- Telescope -->

                <div class="more_tools_tool telescope">
                  <h2><i class="fas fa-globe"></i>Telescope</h2>
                  <p>Since 2011, the Laboratory for Telecommunications at the Faculty of Electrical Engineering has been monitoring activity on a /24 segment of public IP address space (256 IP addresses) that have never been used, referred by a domain or published in connection with any service. Throughout the data collection period, we have noticed an exponential trend of increasing traffic and numerous changes in the sources and the type of traffic.</p>
                  <div class="imgs">
                    <img src="../assets/telescope/telescope-1.png" alt="">
                    <img src="../assets/telescope/telescope-2.png" alt="">
                  </div>
                </div>

              </div>

              <br>
              <!-- Title -->
              <h1 id="contact-title">Contact us</h1>
              <div id="about-contact-holder">
                <ContactUs intent="about"></ContactUs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>

import CloseOverlayButton from '@/components/Common/CloseOverlayButton'
import ContactUs from '@/components/Common/ContactUs'
import LeftPane from '@/components/LandingPage/LeftPane'

export default {
  name: 'MoreTools',
  components: {
    LeftPane, CloseOverlayButton, ContactUs
  },
  mounted() {
    this.$root.$emit("hide-realtime-pane")
    this.$root.$emit("hide-source-info-pane")
  }
}
</script>

<style scoped lang="less">
/* About View */
#moreToolsView {
  padding: 15px 80px 200px 80px;
  pointer-events: auto;
  background: black;
  overflow-y: scroll;
  /* Disable FF scrollbars */
  scrollbar-width: none;
  /* Disable IE 10+ scrollbars */
  -ms-overflow-style: none;
  color: white;
  height: 100vh;

  .header {
    color: white;

    h1 {
      /* Make space for the close pane button */
      padding-right: 40px;
    }
  }

  .body {
    color: white;
    font-weight: 300;
    text-align: justify;
    padding-bottom: 2.5px;
  }

  .logos {
    color: white;
    font-weight: 300;
    text-align: justify;

    img {
      width: 180px;
      height: auto;
      margin-bottom: 35px;
    }
  }
}

#moreToolsView::-webkit-scrollbar {
  display: none;
}

#fullPage {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  /* Initially hide as page loader is shown */
}
</style>
