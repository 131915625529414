<template>
  <div class="copyright_left_holder">
    <div class="copyright_left_holder_inner">
      <p>&copy; CyberLab is built and maintained by the Laboratory for Telecommunications, Faculty of Electrical Engineering, University of Ljubljana.</p>
      <a href="https://www.ltfe.org/" target="_blank"><img src="../../../assets/logo-ltfe-cropped.png" alt="LTFE logo"></a>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'CopytightBottom',
  data: () => ({
    show: false
  }),
  methods: {
    routerLink: function (path) {
      if (this.$route.path === path) {
        router.push("/")
      } else {
        router.push(path);
      }
    }
  }
}
</script>

<style scoped lang="less">
  
</style>