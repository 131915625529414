<template>
  <div v-bind:class="{'column-content': type!=='file', 'graph-row': type==='file'}">
    <div class="title">
      <i v-bind:class="{'fas':true, 'fa-project-diagram': true, 'tooltip-question': type==='file'}"
         v-tooltip="{ content: tooltips['USAGE_EXAMPLE'], trigger: type==='file'? 'hover':'manual' }"></i>
      {{type === 'file'? "USAGE EXAMPLE":"LAST TERMINAL SESSION REPLAY"}}</div>
    <div class="holder">
      <div ref="realtimeTerminal" class="realtimeTerminal">
      </div>
    </div>
  </div>
</template>

<script>
import Typewriter from 'typewriter-effect/dist/core';
import tooltips from "@/store/tooltips";

export default {
  name: 'TerminalSession',
  props: ["type"],
  data: () => ({
    typewriter: {},
    tooltips: tooltips.translates
  }),
  computed: {
    text: {
      get: function () {
        if (this.type === "file") {
          let fileData = this.$store.getters["unpersistedStore/getSearchFileData"];
          if (fileData && fileData.associated_terminal_commands) {
            let obj = {
              last_code: ""
            }
            // eslint-disable-next-line no-unused-vars
            for (const [_key, value] of Object.entries(fileData.associated_terminal_commands)) {
              let txt = value.join("<br />");
              txt = txt.replaceAll("[SLASH]","/")
              obj.last_code = txt;
            }
            return obj
          }
        }
        return this.$store.getters["unpersistedStore/getRealTimeTimespanData"]
      }
    }
  },
  methods: {
    startTyping: function () {
      let data = this.text;
      if (data === null) {
        return;
      }
      const terminal = this.$refs.realtimeTerminal;
      terminal.innerHTML = "";
      if (this.typewriter.terminal != null) {
        this.typewriter.terminal.stop()
      }
      try {
        clearTimeout(this.typewriter.timeout)
        clearInterval(this.typewriter.scrollInterval)
      } catch (e) {
        console.log(e.toString());
      }
      this.typewriter.terminal = new Typewriter(terminal, {
        loop: false,
        delay: 40,
        cursorClassName: "terminalCursor",
        wrapperClassName: "terminalWrapper",
        cursor: "&#9616;"
      });
      let self = this;
      try {
        this.typewriter.terminal.typeString(data.last_code)
            .callFunction(() => {
              self.typewriter.terminal.stop()
              clearInterval(self.typewriter.scrollInterval)
            })
            .start()
      } catch (e) {
        console.log(e.toString());
      }
      this.typewriter.scrollInterval = setInterval(() => {
        terminal.scrollTop = terminal.scrollHeight
      }, 50);
    }
  },
  mounted() {
    this.startTyping();
  }
}
</script>

<style scoped lang="less">
.graph-row {
  pointer-events: all;
  color: white !important;
  background-color: #1C1C1C !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5) !important;
  margin: 0;
  padding: 10px;
  width: calc(100% - 20px);
  height: 100%;
  display: inline-block;

  .title {
    margin-bottom: 10px;

    .fa {
      color: red;
      padding-right: 10px;
    }
  }
  .realtimeTerminal{
    min-height: 250px;
    max-height: 250px;
    overflow-y: auto;
    /* Disable FF scrollbars */
    scrollbar-width: none;
    /* Disable IE 10+ scrollbars */
    -ms-overflow-style: none;
  }
  .realtimeTerminal::-webkit-scrollbar {
    display: none;
  }
}

</style>