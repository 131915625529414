<template>
  <div class="column-content">
    <div class="title"><i class="fas fa-project-diagram"></i>PROTOCOL STATISTICS</div>
    <div class="holder">
      <canvas id="realtimeProtocols"></canvas>
    </div>
  </div>
</template>

<script>
const Chart = require('chart.js');

export default {
  name: 'ProtocolStatistics',
  data: () => ({
  }),
  methods: {
    initGraph: function () {
      const ctx_protocols = document.getElementById("realtimeProtocols").getContext("2d");
      this.graph = new Chart(ctx_protocols, {
        type: 'doughnut',
        data: {
          labels: [],
          backgroundColor: 'rgba(255,0,0,0.2)',
          datasets: [{
            label: ' # of sessions',
            data: [],
            backgroundColor: ['rgba(0, 255, 0, 0.2)', 'rgba(255, 0, 0, 0.75)', 'rgba(0, 255,0, 0.75)', 'rgba(255, 0, 0, 0.2)'],
            borderColor: ['lime', 'red', 'lime', 'red'],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: { position: 'left', labels: {fontColor: 'white'} },
          plugins: {
            datalabels: {
              color: 'white',
              anchor: 'center'
            }
          }
        }
      });
    },
    drawGraph: function () {
      let data = this.$store.getters["unpersistedStore/getRealTimeTimespanData"];
      if (data !== null) {
        this.graph.data.labels = data.top_protocols.protocols;
        this.graph.data.datasets[0].data = data.top_protocols.counts;
        this.graph.update();
      }
    }
  },
  mounted() {
    this.initGraph();
    this.drawGraph();
  },
  created() {
  },
  deactivated() {
    this.graph.destroy();
  }
}
</script>

<style scoped lang="less">

</style>