import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView.vue'
import DatasetsView from '../views/DatasetsView.vue'
import Home from '../views/Home.vue'
import MoreTools from '../views/MoreToolsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/datasets',
    name: 'Datasets',
    component: DatasetsView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/more-tools',
    name: 'MoreTools',
    component: MoreTools
  }
  // {
  //   path: '/search',
  //   name: 'SearchMain',
  //   component: SearchMain
  // },
  // {
  //   path: '/search/:searchText',
  //   name: 'SearchText',
  //   component: SearchMain,
  //   props: true
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
