<template>
  <v-app>
    <div id="fullPage">
      <LeftPane/>
      <div class="rightPaneHolder">
        <div class="rightPaneTop">
          <CloseOverlayButton/>
          <div class="rightPaneTop">
            <div id="datasetsView">
              <header>
                <h1>Download raw data by day</h1>
                Here you can find the collected works of the CyberLab honeynet, unabridged.<br>
                The dataset is being updated nightly, for the previous day.
                <br><br>
                Each file listed below is a daily compilation of all connections starting that day (UTC time), grouped into "attack sessions". Each event in such a
                session
                includes all the data reported by the honeypot software (cowrie3, operating in either low-interaction mode, or backed by a pool of docker containers for
                high interaction). Geolocation data was added on top of that by the logstash software.
                <br><br>
                To be in compliance with EU regulation, we have obfuscated (pseudonymized) all source and destination IP addresses. The same pseudonym always means the
                same
                IP address, either on the source (attacker) or destination (honeypot) side.
              </header>
              <br><br>
              <h2>Data format
                <span id="dropdownFieldsTable" class="dropdown_control" @click="showDatasetsTable = !showDatasetsTable">
                  <i class="far fa-caret-square-up" v-if="showDatasetsTable"></i>
                  <i class="far fa-caret-square-down" v-if="!showDatasetsTable"></i>
                  <span class="show_indicator">{{ showDatasetsTable ? "HIDE" : "SHOW" }}</span>
                </span>
              </h2>
              <div class="datasets_table_holder" v-show="showDatasetsTable">
                <table>
                  <tr>
                    <th>Field</th>
                    <th>Description</th>
                    <th>Example</th>
                  </tr>
                  <tr>
                    <td>session_id</td>
                    <td>Unique ID of the session</td>
                    <td>7efa843ba2d1</td>
                  </tr>
                  <tr>
                    <td>dst_ip_identifier</td>
                    <td>Obfuscated (pseudonymized) destination public IPv4 address of the honeypot node</td>
                    <td>b1e84f073e89a5a22482d2d112acfacb774d478f49a53951369ce029f6956339</td>
                  </tr>
                  <tr>
                    <td>dst_host_identifier</td>
                    <td>Obfuscated (pseudonymized) name of the honeypot node</td>
                    <td>0b6413ddbb3aebd1e5b4f226a60016af8c6138386a8195d453c7dbda2cbbef38</td>
                  </tr>
                  <tr>
                    <td>src_ip_identifier</td>
                    <td>Obfuscated (pseudonymized) IP address of the attacker</td>
                    <td>b1e84f073e89a5a22482d2d112acfacb774d478f49a53951369ce029f6956339</td>
                  </tr>
                  <tr>
                    <td>eventid</td>
                    <td>Event id of the session in the cowrie honeypot</td>
                    <td>cowrie.session.connect</td>
                  </tr>
                  <tr>
                    <td>timestamp</td>
                    <td>UTC time of the event</td>
                    <td>2019-11-01T00:00:24.107618Z</td>
                  </tr>
                  <tr>
                    <td>message</td>
                    <td>Message of the cowrie honeypot; any public source and IP address mentions are replaced with the pseudonym. Other IPs, including private
                      infrastructure addresses are left as is.
                    </td>
                    <td>New connection: b1e84f073e89a5a22482d2d112acfacb774d478f49a53951369ce029f6956339:44502 (192.168.144.2:2222) [session: 7efa843ba2d1]</td>
                  </tr>
                  <tr>
                    <td>protocol</td>
                    <td>Protocol used in the cowrie honeypot; either ssh or telnet</td>
                    <td>ssh</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/postal_code</td>
                    <td>Source IP postal code as (determined by the logstash node)</td>
                    <td>52100</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/continent_code</td>
                    <td>Source IP continent code (as determined by the logstash node)</td>
                    <td>EU</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/country_code3</td>
                    <td>Source IP country code3 (as determined by the logstash node)</td>
                    <td>IT</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/region_name</td>
                    <td>Source IP region name (as determined by the logstash node)</td>
                    <td>Province of Arezzo</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/latitude</td>
                    <td>Source IP latitude (as determined by the logstash node)</td>
                    <td>43.4167</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/longitude</td>
                    <td>Source IP longitude (as determined by the logstash node)</td>
                    <td>11.8833</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/country_name</td>
                    <td>Source IP full country name (as determined by the logstash node)</td>
                    <td>Italy</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/location/</td>
                    <td>Source IP location object (includes lon and lat), as appended by the logstash node; could potentially differ from the longitude and latitude given
                      above
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>geolocation_data/location/lat</td>
                    <td>Source IP location latitude, as appended by the logstash node; could potentially differ from the latitude given above</td>
                    <td>43.4167</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/location/lon</td>
                    <td>Source IP location longitude, as appended by the logstash node; could potentially differ from the longitude given above</td>
                    <td>11.883</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/timezone</td>
                    <td>Source IP timezone</td>
                    <td>Europe/Rome</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/country_code2</td>
                    <td>Source IP country code2</td>
                    <td>IT</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/region_code</td>
                    <td>Source IP region code</td>
                    <td>AR</td>
                  </tr>
                  <tr>
                    <td>geolocation_data/city_name</td>
                    <td>Source IP city name</td>
                    <td>Arezzo</td>
                  </tr>
                  <tr>
                    <td>src_port</td>
                    <td>Source TCP port</td>
                    <td>44502</td>
                  </tr>
                  <tr>
                    <td>sensor</td>
                    <td>Sensor name, which serves to identify our experiment configuration</td>
                    <td>ubuntu-ssh</td>
                  </tr>
                  <tr>
                    <td>arch</td>
                    <td>Represents the CPU/OS architecture emulated by cowrie</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>duration</td>
                    <td>Session duration in seconds</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>ssh_client_version</td>
                    <td>Attacker's SSH client version</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>username</td>
                    <td>Username used when attempting to log in; only set at the login attempt at the beginning of session, otherwise null</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>password</td>
                    <td>Password used when attempting to log in; only set at the login attempt at the beginning of session, otherwise null</td>
                    <td>hunter2</td>
                  </tr>
                  <tr>
                    <td>hasshAlgorithms</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>macCS</td>
                    <td>HMAC algorithms supported by the client (SSH MAC supported in the sesssion)</td>
                    <td>["hmac-sha1", "hmac-md5", "hmac-sha2-256"]</td>
                  </tr>
                  <tr>
                    <td>langCS</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>compCS</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>encCS</td>
                    <td>Encryption algorithms supported by the client</td>
                    <td>["3des-cbc", "aes256-ctr", "aes256-cbc", "aes192-ctr", "aes192-cbc", "aes128-cbc", "blowfish-cbc", "aes128-ctr", "cast128-cbc"]</td>
                  </tr>
                  <tr>
                    <td>hassh</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>kexAlgs</td>
                    <td>Key exchange algorithms supported by the client</td>
                    <td>["diffie-hellman-group14-sha1", "diffie-hellman-group1-sha1", "diffie-hellman-group-exchange-sha1", "diffie-hellman-group-exchange-sha256"]</td>
                  </tr>
                  <tr>
                    <td>keyAlgs</td>
                    <td>Public key algorithms supported by the client</td>
                    <td>["ssh-rsa", "ssh-dss"]</td>
                  </tr>
                  <tr>
                    <td>outfile</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>destfile</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>duplicate</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>shasum</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>url</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>ttylog</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>size</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>filename</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                  <tr>
                    <td>data</td>
                    <td>TBD</td>
                    <td>null</td>
                  </tr>
                </table>
              </div>
              <br><br>
              <h2>List of datasets by day <span id="dropdownGetDatasets" class="dropdown_control" @click="showContactForm = !showContactForm">
                <i class="far fa-caret-square-up" v-if="showContactForm"></i>
                <i class="far fa-caret-square-down" v-if="!showContactForm"></i>
                <span class="show_indicator">{{ showContactForm ? "HIDE" : "SHOW" }}</span></span></h2>
              <div class="get_datasets_holder">
                <div id="datasets-contact-holder" v-show="showContactForm" v-if="!contactIsSent">
                  <ContactUs intent="datasets"></ContactUs>
                </div>
                <div id="datasets-downloads-holder" v-show="showContactForm" v-if="contactIsSent">
                  <ul>
                    <li v-for="dataset in dataSetList" :key="dataset.file_name">
                      <a v-bind:href="dataset.link" target="_blank">{{dataset.file_name}}</a> ({{dataset.size}})
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>

import LeftPane from '@/components/LandingPage/LeftPane'
import CloseOverlayButton from '@/components/Common/CloseOverlayButton'
import ContactUs from '@/components/Common/ContactUs'
import axios from "axios";

export default {
  name: 'Home',
  components: {
    LeftPane, CloseOverlayButton, ContactUs
  },
  data: () => ({
    showDatasetsTable: false,
    showContactForm: true,
    dataSetList: []
  }),
  computed: {
    contactIsSent: {
      get: function () {
        return this.$store.getters["globePersistedStore/isContactFormSubmitted"];
      }
    }
  },
  watch: {
    contactIsSent: function () {
      if (this.contactIsSent) {
        this.getFileList();
      }
    }
  },
  methods:{
    getFileList: function () {
      axios
          .get(this.$store.getters["globePersistedStore/getAPIUrl"] + '/datasets',{
            headers: {"Content-Type": "application/json"}
          })
          .then( response => {
            this.dataSetList = response.data.datasets_info;
          });
    }
  },
  mounted() {
    this.$root.$emit("hide-realtime-pane")
    this.$root.$emit("hide-source-info-pane")
    if(this.contactIsSent){
      this.getFileList();
    }
  }
}
</script>

<style scoped lang="less">
/* Datasets View */
#datasetsView {
  pointer-events: auto;
  background-color: black;
  padding: 10px 80px 50px 80px;
  text-align: justify;
  height: 100vh;
  font-family: 'Lato', sans-serif;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  /* Disable FF scrollbars */
  scrollbar-width: none;
  /* Disable IE 10+ scrollbars */
  -ms-overflow-style: none;

  header {
    font-weight: 300;

    h1 {
      padding-right: 45px;
      margin-top: 25px
    }
  }

  .dropdown_control {
    color: red;
    float: right;
    padding-top: 0.6em;
    font-size: 0.6em;
    cursor: pointer;
    pointer-events: auto;

    i {
      margin-right: 3px;
      padding-bottom: 1px;
      transition: all 0.2s ease-in-out;
    }
  }

  .dropdown_control:hover i {
    color: white;
  }

  .datasets_table_holder {
    overflow: auto;
    font-weight: 300;
  }

  table {
    border-collapse: collapse;
    font-size: 0.8em;

    td, th {
      border: 1px solid white;
      padding: 5px;
    }

    th {
      background-color: #222;
    }

    td:nth-child(1) {
      background-color: #333;
    }
  }
}

#fullPage {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  /* Initially hide as page loader is shown */
}

/* Disable WebKit scrollbars */
#datasetsView::-webkit-scrollbar {
  display: none;
}
#datasets-downloads-holder {
  font-weight: 300;
}
#datasets-downloads-holder a {
  color: red;
}

</style>
