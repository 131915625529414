<template>
  <div id="closeOverlayPaneButton" v-on:click="close()">
    <i class="fas fa-times"></i>
  </div>
</template>

<script>
import router from '../../router'

export default {
  name: 'CloseOverlayButton',
  data: () => ({}),
  methods: {
    close: function () {
      router.push("/");
    }
  }
}
</script>

<style scoped lang="less">
/* Close right pane overlay (datasets or about view) */
#closeOverlayPaneButton {
  pointer-events: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 40px;
  right: calc(30px - 2px);
  height: 30px;
  width: 30px;
  background: black;
  color: red;
  border: 1px solid red;
  font-size: 1em;
  transition: all 0.1s linear;
}

#closeOverlayPaneButton:hover {
  background: red;
  color: black;
  cursor: pointer;
}
</style>