<template>
  <div class="data_control_left">
    <div class="control_header">
      <div class="control_icon"><i class="fas fa-project-diagram"></i></div>
      <div class="control_title">REALTIME DATA</div>
      <div class="control_checkbox">
        <label class="switch">
          <input id="realtimeDataControlCheckbox" type="checkbox" v-model="enabled">
          <span class="slider"></span>
        </label>
      </div>
    </div>
    <transition name="slide-fade">
      <div id="realtimeDataControlBody" class="control_body" v-if="showBody">
        <!-- Realtime Data Control Body -->
        <div id="realtimeDataHackers" class="number_display">
          <div class="number_big">{{ nmbOfActiveHackers }}</div>
          <div class="number_description"><span class="bold">HACKERS</span><br><span class="light">ACTIVE</span></div>
        </div>
        <div id="realtimeDataNodes" class="number_display">
          <div class="number_big">{{ nmbOfNodesAttacked }}</div>
          <div class="number_description"><span class="bold">NODES</span><br><span class="light">ATTACKED</span></div>
        </div>
        <div id="realtimeDataTimespanSelector">
          <p>Timespan of bottom pane data:</p>
          <div id="timespanButtons">
            <div v-bind:class="{'timespan_button':true,'selected':bottomPaneTimeSpan === 10}" v-on:click="setBottomPaneTimeSpan(10)">10 MIN</div>
            <div v-bind:class="{'timespan_button':true,'selected':bottomPaneTimeSpan === 60}" v-on:click="setBottomPaneTimeSpan(60)">1 HR</div>
            <div v-bind:class="{'timespan_button':true,'selected':bottomPaneTimeSpan === 720}" v-on:click="setBottomPaneTimeSpan(720)">12 HRS</div>
            <div v-bind:class="{'timespan_button':true,'selected':bottomPaneTimeSpan === 1440}" v-on:click="setBottomPaneTimeSpan(1440)">1 DAY</div>
          </div>
        </div>
      </div>
    </transition>
    <div id="realtimeDataControlFooter" class="control_footer">
      <div v-bind:class="{'control_icon': true, 'is-opened':!showBody}" v-on:click="toggleView()"><i class="fas fa-chevron-up"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RealtimeDataControl',
  data: () => ({
    showBody: true
  }),
  computed: {
    enabled: {
      get: function () {
        return this.$store.getters["globePersistedStore/isRealtimeDataEnabled"];
      },
      set: function (){
        this.$store.commit("globePersistedStore/toggleRealtimeData")
      }
    },
    nmbOfActiveHackers: {
      get: function () {
        return this.$store.getters["globePersistedStore/getActiveHackers"];
      }
    },
    nmbOfNodesAttacked: {
      get: function () {
        return this.$store.getters["globePersistedStore/getNodesAttacked"];
      }
    },
    bottomPaneTimeSpan: {
      get: function () {
        return this.$store.getters["globePersistedStore/getBottomPaneTimeSpan"];
      }
    }
  },
  methods: {
    setBottomPaneTimeSpan: function (value) {
      this.$store.commit('globePersistedStore/setBottomPaneTimeSpan', value);
    },
    toggleView: function () {
      this.showBody = !this.showBody;
    }
  },
  mounted: function () {
    this.$store.dispatch("globePersistedStore/updateRealtimeData");
    this.$root.$on("hide-realtime-pane",()=>{
      this.showBody = false;
    })
  },
  deactivated() {
    this.$root.$off("hide-realtime-pane");
  }
}
</script>

<style scoped lang="less">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.25s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to {
  margin-top: -300px;
}

/* Realtime Data Timespan */
#realtimeDataTimespanSelector {
  margin-top: 20px;
}
#realtimeDataTimespanSelector p {
  text-transform: uppercase;
  font-weight: 300;
  color: white;
  font-size: 13px;
  text-align: center;
  text-justify: distribute;
  letter-spacing: 0.070em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

#timespanButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.timespan_button {
  border: 1px solid red;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  transition: all 0.1s ease-in-out;
  background: transparent;
}
.timespan_button:hover {
  cursor: pointer;
  border-color: white;
}
.timespan_button.selected {
  background: red;
  color: black;
}
.timespan_button.selected:hover {
  color: black;
  border-color: red;
}
</style>