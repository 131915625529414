<template>
  <div id="rightPaneBottom" v-bind:class="{'indicateScroll':indicateScroll, 'bottom-pane-hidden': !enabled, 'left-pane-hidden': !showLeftPane}" ref="rightPaneBottom"
       v-bind:style="{'margin-bottom': rightPaneMarginBottom+'px'}" v-on:scroll="onscrollHandler">
    <div class="pull_tab" v-bind:class="{'pull_tab':true, 'bottom-pane-hidden': !enabled}">
      <div class="control_icon" v-on:click="toggleBottomPane"><i class="fas fa-chevron-down"></i></div>
    </div>
    <div class="content" v-if="enabled">
      <div class="row" id="bottomPaneTip" v-if="!enabledRealTimeData && !enabledSourceInfo">
        <p>USE THE LEFT PANE TO ENABLE DATA SOURCES</p>
      </div>
      <div class="row" v-if="enabledRealTimeData">
        <div class="column-1">
          <AttackPerCountry ref="attackPerCountryGraph"></AttackPerCountry>
        </div>
        <div class="column-2">
          <TerminalSession ref="terminalGraph"></TerminalSession>
        </div>
      </div>
      <div class="row" v-if="enabledRealTimeData">
        <div class="column-1">
          <ImportantNodes ref="importantNodesGraph"></ImportantNodes>
        </div>
        <div class="column-2">
          <ProtocolStatistics ref="protocolsGraph"></ProtocolStatistics>
        </div>
      </div>
      <div class="row" v-if="enabledSourceInfo">
        <ImportantAsns ref="topAsnsGraph"></ImportantAsns>
      </div>
      <div class="row" v-if="enabledSourceInfo">
        <ImportantDatacenters ref="topDatacentersGraph"></ImportantDatacenters>
      </div>
      <div class="row" v-if="enabledSourceInfo">
        <ImportantResidentialAsns ref="topResidentialsGraph"></ImportantResidentialAsns>
      </div>

    </div>

  </div>
</template>

<script>
import AttackPerCountry from '@/components/BottomPaneGraphs/AttackPerCountry'
import TerminalSession from '@/components/BottomPaneGraphs/TerminalSession'
import ImportantNodes from '@/components/BottomPaneGraphs/ImportantNodes'
import ProtocolStatistics from '@/components/BottomPaneGraphs/ProtocolStatistics'
import ImportantAsns from '@/components/BottomPaneGraphs/ImportantAsns'
import ImportantDatacenters from '@/components/BottomPaneGraphs/ImportantDatacenters'
import ImportantResidentialAsns from '@/components/BottomPaneGraphs/ImportantResidentialAsns'
import axios from "axios";

export default {
  name: 'RightPaneBottom',
  components: {
    AttackPerCountry, TerminalSession, ImportantNodes, ProtocolStatistics, ImportantAsns, ImportantDatacenters, ImportantResidentialAsns
  },
  data: () => ({
    indicateScroll: true,
    rightPaneMarginBottom: 0,
    realTimeDataTimer: null,
    sourceInfoDataTimer: null
  }),
  computed: {
    showLeftPane: {
      get: function () {
        return this.$store.getters["globePersistedStore/showLeftPane"];
      }
    },
    enabled: {
      get: function () {
        return this.$store.getters["globePersistedStore/isBottomPaneEnabled"];
      },
      set: function () {
        this.$store.commit("globePersistedStore/toggleBottomPane")
      }
    },
    enabledRealTimeData: {
      get: function () {
        return this.$store.getters["globePersistedStore/isRealtimeDataEnabled"];
      }
    },
    bottomPaneTimeSpan: {
      get: function () {
        return this.$store.getters["globePersistedStore/getBottomPaneTimeSpan"];
      }
    },
    enabledSourceInfo: {
      get: function () {
        return this.$store.getters["globePersistedStore/isSourceInfoEnabled"];
      }
    }
  },
  watch: {
    enabledRealTimeData: function () {
      this.checkRealtimeDataDisplay();
    },
    bottomPaneTimeSpan: function () {
      this.checkRealtimeDataDisplay();
    },
    enabledSourceInfo: function () {
      this.checkSourceInfoDisplay();
    }
  },
  methods: {
    checkRealtimeDataDisplay: function () {
      if (this.realTimeDataTimer) {
        clearTimeout(this.realTimeDataTimer);
      }
      if (this.enabledRealTimeData) {
        this.getRealTimeData();
      }
    },
    checkSourceInfoDisplay: function () {
      if (this.sourceInfoDataTimer) {
        clearTimeout(this.sourceInfoDataTimer);
      }
      if (this.enabledSourceInfo) {
        if (!this.enabledRealTimeData) {
          this.getRealTimeData();
        }
      }
    },
    toggleBottomPane: function () {
      this.$store.commit('globePersistedStore/toggleBottomPane');
      this.setMarginBottom();
      if (!this.$store.getters["globePersistedStore/isBottomPaneEnabled"]) {
        this.indicateScroll = false;
      } else {
        this.indicateScroll = true;
      }
    },
    setMarginBottom: function () {
      if (!this.enabled) {
        this.rightPaneMarginBottom = -(this.$refs.rightPaneBottom.offsetHeight - 20);
        this.indicateScroll = false;
      } else {
        this.rightPaneMarginBottom = 0;
      }
    },
    getRealTimeData: function () {
      axios
          .get(this.$store.getters["globePersistedStore/getAPIUrl"] + '/chart_data?timespan=' + this.$store.getters["globePersistedStore/getBottomPaneTimeSpan"], {
            headers: {"Content-Type": "application/json"}
          })
          .then(response => {
            this.$store.commit("unpersistedStore/setRealTimeTimespanData", response.data);
            if (this.$refs.attackPerCountryGraph) {
              this.$refs.attackPerCountryGraph.drawGraph();
            }
            if (this.$refs.attackPerCountryGraph) {
              this.$refs.importantNodesGraph.drawGraph();
            }
            if (this.$refs.protocolsGraph) {
              this.$refs.protocolsGraph.drawGraph();
            }
            if (this.$refs.protocolsGraph) {
              this.$refs.protocolsGraph.drawGraph();
            }
            if (this.$refs.terminalGraph) {
              this.$refs.terminalGraph.startTyping();
            }
            if (this.$refs.topAsnsGraph) {
              this.$refs.topAsnsGraph.drawGraph();
            }
            if (this.$refs.topDatacentersGraph) {
              this.$refs.topDatacentersGraph.drawGraph();
            }
            if (this.$refs.topResidentialsGraph) {
              this.$refs.topResidentialsGraph.drawGraph();
            }
            this.realTimeDataTimer = setTimeout(this.getRealTimeData, 60 * 1000);
          })
    },
    onscrollHandler: function () {
      this.indicateScroll = false;
    }
  },
  mounted() {
    this.setMarginBottom();
    this.checkRealtimeDataDisplay();
    this.checkSourceInfoDisplay();
  }
}
</script>

<style scoped lang="less">
/* RIGHT PANE BOTTOM */
#rightPaneBottom {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-top: auto;
  height: 50vh;
  pointer-events: auto;
  max-width: calc(100vw - 350px);
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: y mandatory;
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;

  .pull_tab {
    background: rgba(0, 0, 0, 0.7);
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: calc((100vw - 350px));

    .control_icon {
      color: gray;
      transition: all 0.5s ease-in-out;
    }

    .control_icon:hover {
      color: white;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: hidden;
      overflow-x: hidden;
      scroll-snap-align: start;
      /* Center items with account for pull tab and
      apply scroll-snap with the correct offset */
      height: calc(50vh);
      padding: 40px 0 20px 0;

      .holder {
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: relative;
        /* Full container height - two line title height and margin */
        height: calc(100% - 55px - 15px);
      }

      .holder::-webkit-scrollbar {
        display: none;
      }
    }

    .row:first-child {
      height: calc(50vh);
      padding: 20px 0 20px 0;
    }

    .column-1 {
      width: 50%;
      border-right: 0.5px dashed gray;
      height: 100%;

      .column-content {
        margin-right: 25px;
        height: 100%;
      }
    }

    .column-2 {
      width: 50%;
      max-width: 50%;
      height: 100%;

      .column-content {
        margin-left: 25px;
        height: 100%;
      }
    }
  }

}

#rightPaneBottom.left-pane-hidden {
  max-width: 100vw;
}

#rightPaneBottom::-webkit-scrollbar {
  display: none;
}

#rightPaneBottom.bottom-pane-hidden {
  margin-bottom: -287px;

  .pull_tab .control_icon {
    transform: rotate(180deg);
  }
}

.column-content .title {
  font-family: 'Lato', sans-serif;
  padding-bottom: 15px;
  height: 55px;
}

/* Realtime Data */
#realtimeTerminal {
  overflow: scroll;
  color: lime;
  font-weight: 300;
  font-size: 12px;
  height: 100%;
  /* Disable FF scrollbars */
  scrollbar-width: none;
  /* Disable IE 10+ scrollbars */
  -ms-overflow-style: none;
}

/* Right Pane Bottom Scroll Indicator */
#rightPaneBottom.indicateScroll::before {
  animation: bounceRed 1s ease infinite;
  content: '╲╱';
  font-size: 0.75rem;
  color: #fff;
  position: absolute;
  text-align: center;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  bottom: 50px;
  right: 70px;
}

#rightPaneBottom.indicateScroll::after {
  animation: bounceRed 1s ease infinite;
  color: #fff;
  content: 'scroll';
  position: absolute;
  text-align: center;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  bottom: 75px;
  right: 62px;
}

@keyframes bounceRed {
  50% {
    transform: translateY(25%);
    color: red;
  }
  100% {
    transform: translateY(0);
    color: white;
  }
}
</style>