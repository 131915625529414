import actions from "@/store/unpersisted/actions";
import getters from "@/store/unpersisted/getters";
import mutations from "@/store/unpersisted/mutations";

export const unpersistedStore = {
    namespaced: true,
    state: {
        loader: 0,
        realtimeData: {
            arcData: [],
            timeSpanData: null
        },
        sourceInfo: {
            data: []
        },
        attackDensity: {
            data: []
        },
        searchData: {
            ipData: {},
            asnData: {},
            fileData: {},
            ipAbuseData: {
                ip: "",
                malicious_activity_score_timeline: {},
                abuse_type_counter: {}
            },
            ipEventsData: {},
            ipFilesData: [],
            asnAbuseData: {
                asn: "",
                malicious_activity_score_timeline: {}
            },
            asnEventsData: {}
        }
    },
    getters,
    mutations,
    actions
}