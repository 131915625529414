<template>
  <div id="globeHolder" v-show="show" ref="globeHolder" v-bind:style="{'padding-left': showLeftPane? '175px': 0}">
    <div id="globe" ref="globe"></div>
  </div>
</template>

<script>
import Globe from 'globe.gl';
import axios from 'axios';

const d3 = require("d3");

import globeImageUrl from "../../assets/earth-night-resized.jpg";
import backgroundImageUrl from "../../assets/night-sky.png";

export default {
  name: 'Globe',
  data: () => ({
    show: false,
    masterGlobe: null,
    realTimeDataTimer: null,
    sourceInfoTimer: null,
    attackDensityTimer: null,
    resizeTimer: null,
    demoTimer: null,
    weightColor: d3.scaleSequentialSqrt(d3.interpolateYlOrRd).domain([0, 1e7])
  }),
  computed: {
    showLeftPane: {
      get: function () {
        return this.$store.getters["globePersistedStore/showLeftPane"];
      }
    },
    enabledRealTimeData: {
      get: function () {
        return this.$store.getters["globePersistedStore/isRealtimeDataEnabled"];
      }
    },
    bottomPaneTimeSpan: {
      get: function () {
        return this.$store.getters["globePersistedStore/getBottomPaneTimeSpan"];
      }
    },
    enabledSourceInfo: {
      get: function () {
        return this.$store.getters["globePersistedStore/isSourceInfoEnabled"];
      }
    },
    sourceInfoBorder: {
      get: function () {
        return this.$store.getters["globePersistedStore/getSourceInfoBorder"];
      }
    },
    enabledAttackDensity: {
      get: function () {
        return this.$store.getters["globePersistedStore/isAttackDensityEnabled"];
      }
    },
    enabledDemo: {
      get: function () {
        return this.$store.getters["globePersistedStore/isDemoModeEnabled"];
      }
    }
  },
  watch: {
    enabledDemo: function () {
      this.displayDemoMode();
    },
    enabledRealTimeData: function () {
      this.displayRealTimeData();
    },
    enabledSourceInfo: function () {
      this.displaySourceInfoData();
    },
    bottomPaneTimeSpan: function () {
      this.displayRealTimeData();
    },
    sourceInfoBorder: function () {
      this.sourceInfoDataDisplay();
    },
    enabledAttackDensity: function () {
      this.displayAttackDensityData();
    },
    showLeftPane: function () {
      this.windowResizeEventHandler();
    }
  },
  methods: {
    windowResizeEventHandler() {
        this.windowResizeEventHandlerForTimer();
        if(this.resizeTimer === null){
          this.resizeTimer = setTimeout(function (){
            this.windowResizeEventHandlerForTimer();
            this.resizeTimer = null;
          }.bind(this),1000);
        }
    },
    windowResizeEventHandlerForTimer(){
      this.masterGlobe.width(this.$refs.globeHolder.clientWidth);
      this.masterGlobe.height(this.$refs.globeHolder.clientHeight);
    },
    rotateGlobe: function () {
      let rotation_step = 0.2 * this.masterGlobe.pointOfView().altitude / 2;
      this.masterGlobe.pointOfView({
        lat: this.masterGlobe.pointOfView().lat,
        lng: this.masterGlobe.pointOfView().lng + rotation_step,
        altitude: this.masterGlobe.pointOfView().altitude
      });
      this.demoTimer = setTimeout(this.rotateGlobe, 70);
    },
    displayDemoMode: function () {
      if (this.enabledDemo) {
        this.demoTimer = setTimeout(this.rotateGlobe, 70);
      } else {
        if (this.demoTimer) {
          clearTimeout(this.demoTimer);
        }
      }
    },
    realTimeDataDisplay: function () {
      this.masterGlobe.arcsData(this.$store.getters["unpersistedStore/getArcData"]);
    },
    realTimeDataRest: function () {
      axios
          .get(this.$store.getters["globePersistedStore/getAPIUrl"] + '/traffic?timespan=' + this.$store.getters["globePersistedStore/getBottomPaneTimeSpan"], {
            headers: {"Content-Type": "application/json"}
          })
          .then(response => {
            this.$store.commit("unpersistedStore/setArcData", response.data.data);
            this.realTimeDataDisplay();
            this.realTimeDataTimer = setTimeout(this.realTimeDataRest, 300 * 1000);
          })
    },
    sourceInfoDataDisplay: function () {
      const getSourceInfoData = this.$store.getters["unpersistedStore/getSourceInfoData"];
      if(!getSourceInfoData || !getSourceInfoData.max_nodes_attacked){
        return ;
      }
      const max_nodes_attacked = getSourceInfoData.max_nodes_attacked;
      const data = getSourceInfoData.points
          .filter(d => d.nodes_attacked >= this.$store.getters["globePersistedStore/getSourceInfoBorder"])
      const self = this;
      this.masterGlobe.labelsData(data)
          .labelLat(d => d.location_lat)
          .labelLng(d => d.location_lng)
          .labelText(d => d.nodes_attacked)
          .labelSize(d => Math.sqrt(d.nodes_attacked) * 0.16)
          .labelDotRadius(d => Math.sqrt(d.nodes_attacked) * 0.1)
          .labelColor((d) => 'hsla(' + ((1 - d.nodes_attacked / max_nodes_attacked) * 55) + ', 100, 50, 0.75)')
          .labelLabel(d => `${d.city}, ${d.country}<br>Nodes attacked: ${d.nodes_attacked}<br>Total sessions: ${d.session_count}`)
          .onLabelClick(d => self.$router.push({name: 'SearchText', params: {"searchText": d.ip}}))
          .labelResolution(2)
    },
    sourceInfoDataRest: function () {
      axios
          .get(this.$store.getters["globePersistedStore/getAPIUrl"] + '/top_hubs', {
            headers: {"Content-Type": "application/json"}
          })
          .then(response => {
            this.$store.commit("unpersistedStore/setSourceInfoData", response.data);
            this.sourceInfoTimer = setTimeout(this.sourceInfoDataRest, 300 * 1000);
            this.sourceInfoDataDisplay();

          })
    },
    attackDensityDataRest: function () {
      axios
          .get(this.$store.getters["globePersistedStore/getAPIUrl"] + '/top_locations', {
            headers: {"Content-Type": "application/json"}
          })
          .then(response => {
            this.$store.commit("unpersistedStore/setAttackDensityData", response.data);
            this.attackDensityTimer = setTimeout(this.attackDensityDataRest, 300 * 1000);
            this.attackDensityDataDisplay();

          })
    },
    attackDensityDataDisplay: function () {
      const data = this.$store.getters["unpersistedStore/getAttackDensityData"];
      if(!data || !data.max_events){
        return ;
      }
      let max_events = data.max_events;
      this.weightColor = d3.scaleSequentialSqrt(d3.interpolateYlOrRd).domain([0, max_events]);
      this.masterGlobe.hexBinPointsData(data.points)
          .hexBinPointWeight('cnt')
          .hexBinMerge(false)
          .hexAltitude(d => 1e-3 * Math.sqrt(d.sumWeight))
          .hexTopColor(d => this.weightColor(d.sumWeight))
          .hexSideColor(d => this.weightColor(d.sumWeight))
          .hexTransitionDuration(2000)
          .enablePointerInteraction(false);
    },
    displayRealTimeData: function () {
      if (this.enabledRealTimeData) {
        this.realTimeDataRest();
      } else {
        this.masterGlobe.arcsData([]);
        if (this.realTimeDataTimer) {
          clearTimeout(this.realTimeDataTimer);
        }
      }
    },
    displaySourceInfoData: function () {
      if (this.enabledSourceInfo) {
        this.sourceInfoDataRest();
      } else {
        this.masterGlobe.labelsData([]);
        if (this.sourceInfoTimer) {
          clearTimeout(this.sourceInfoTimer);
        }
      }
    },
    displayAttackDensityData: function () {
      if (this.enabledAttackDensity) {
        this.attackDensityDataRest();
      } else {
        this.masterGlobe.hexBinPointsData([]);
        if (this.attackDensityTimer) {
          clearTimeout(this.attackDensityTimer);
        }
      }
    }
  },
  mounted: function () {
    let self = this;
    if (this.$store.getters["globePersistedStore/isFirstLoad"]) {
      setTimeout(() => {
        self.show = true;
      }, 4000)
    } else {
      self.show = true;
    }

    const OPACITY = 0.5;
    if (!this.masterGlobe) {
      this.masterGlobe = Globe({
        waitForGlobeReady: true,
        animateIn: false
      })(this.$refs.globe)
          .globeImageUrl(globeImageUrl)
          .backgroundImageUrl(backgroundImageUrl)
          // .backgroundColor("#000000")
          // aim at continental US centroid
          .pointOfView({lat: 39.6, lng: -98.5, altitude: 2})
          .arcLabel(d => `${d.protocol}: ${d.source_cn} &#8594; ${d.node_macrolocation}`)
          .arcStartLat(d => +d.location_lat)
          .arcStartLng(d => +d.location_lng)
          .arcEndLat(d => +d.node_lat)
          .arcEndLng(d => +d.node_lng)
          .arcDashLength(0.15)
          .arcDashGap(0.05)
          .arcDashInitialGap(() => Math.random())
          .arcDashAnimateTime(10000)
          .arcColor(() => [`rgba(0, 255, 0, ${OPACITY})`, `rgba(255, 0, 0, ${OPACITY})`])
          .arcsTransitionDuration(1000)
          .pointColor(() => 'orange')
          .pointAltitude(0)
          .pointRadius(0.12)
          .pointsMerge(true)
    }

    // check for demo mode
    setTimeout(this.displayDemoMode, 1000);

    // check for arcData
    setTimeout(this.displayRealTimeData, 200);
    if (this.enabledRealTimeData && this.$store.getters["unpersistedStore/getArcData"].length) {
      this.realTimeDataDisplay();
    }

    setTimeout(this.displaySourceInfoData, 200);
    if (this.enabledRealTimeData && this.$store.getters["unpersistedStore/getSourceInfoData"]) {
      this.sourceInfoDataDisplay();
    }

    setTimeout(this.displayAttackDensityData, 200);
    if (this.enabledAttackDensity && this.$store.getters["unpersistedStore/getAttackDensityData"]) {
      this.attackDensityDataDisplay();
    }

    // check for resize
    setTimeout(this.windowResizeEventHandler, 1000);
  },
  created() {
    window.addEventListener("resize", this.windowResizeEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResizeEventHandler);
    if (this.realTimeDataTimer) {
      clearTimeout(this.realTimeDataTimer);
    }
    if (this.sourceInfoTimer) {
      clearTimeout(this.sourceInfoTimer);
    }
    if (this.demoTimer) {
      clearTimeout(this.demoTimer);
    }
    if (this.attackDensityTimer) {
      clearTimeout(this.attackDensityTimer);
    }
    this.masterGlobe._destructor();
  }
}
</script>

<style scoped lang="less">
/* Globe */
#globeHolder {
  height: 100vh;
  width: 100vw;
  /* Padding je eksperimentalno dolocen :) */
  padding-left: 175px;
  overflow: hidden;
  background: black;
  position: fixed;
  z-index: -5;
  pointer-events: auto;
  transition: all 1s ease-in-out;
}

</style>