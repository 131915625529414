<template>
  <div id="attackDensityControl" class="data_control_left">
    <div id="attackDensityControlHeader" class="control_header">
      <div class="control_icon"><i class="fas fa-chart-bar"></i></div>
      <div class="control_title">ATTACK DENSITY</div>
      <div class="control_checkbox">
        <label class="switch">
          <input id="attackDensityControlCheckbox" type="checkbox" v-model="enabled">
          <span class="slider"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttackDensityControl',
  data: () => ({
  }),
  computed: {
    enabled: {
      get: function () {
        return this.$store.getters["globePersistedStore/isAttackDensityEnabled"];
      },
      set: function () {
        this.$store.commit("globePersistedStore/toggleAttackDensity")
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>