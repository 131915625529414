<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data: () => ({}),
  created() {
    try {
      let scripts = document.getElementsByTagName("script");
      let currentScriptUrl = (document.currentScript || scripts[scripts.length - 1]).src;
      let arr = currentScriptUrl.split(".");
      const hash = arr[arr.length - 2];
      if (hash !== this.$store.getters["globePersistedStore/getHash"] || hash.indexOf("localhost") !== -1) {
        console.log("Reset local storage!");
        this.$store.commit("globePersistedStore/resetState");
        this.$store.commit("globePersistedStore/setHash", hash);
      }
    } catch (e) {
      console.log("Get current script error");
    }

    this.$store.commit("globePersistedStore/setFirstLoad", true);
    this.$store.commit("globePersistedStore/setLeftPane", true);
    this.$store.commit("globePersistedStore/setDemoMode", true);
    this.$store.dispatch("globePersistedStore/updateRealtimeData");
  }
};
</script>


